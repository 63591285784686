import { API_URIS } from '@/constants/api'
import axios from 'axios'
import { defineStore } from 'pinia'

export const useStore = defineStore('profile', {
	state: () => ({
		profileInfo: null,
	}),
	actions: {
		async getProfileInfo() {
			try {
				const response = await axios.get(API_URIS.userProfileURL)
				this.profileInfo = response.data
			} catch (err) {
				throw err
			}
		},
		setProfileInfo(profileInfo) {
			this.profileInfo = profileInfo
		},
	},
})
