<template>
  <div class="main_layout_wrapper">
    <div class="challenge_layout">
      <div class="challenge_layout">
        <Header :isAnonymousUser="true"  />
        <div class="middle">
          <router-view class="router_view" v-slot="{ Component }">
            <Transition name="fade" mode="out-in">
              <component :isAnonymousUser="true"  :is="Component" />
            </Transition>
          </router-view>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "@/components/app/Header.vue";
import { useStore as useProfileStore } from "@/store/profile";
import {mapStores} from "pinia";

export default {
  components: {
    Header
  },
  data() {
    return {}
  },
  computed: {
    ...mapStores(useProfileStore),
  },
  methods: {
    async getProfileInfo() {
      await this.profileStore.getProfileInfo();
    },
  },
  async created() {
    await this.getProfileInfo();
  }
};
</script>

<style scoped>
.router_view {
  max-height: 100% !important;
}

@media (max-width: 1000px) {
  .router_view {
    padding: 24px 0 108px 0;
  }
}
@media (max-width: 550px) {
  .router_view {
    padding-bottom: 94px;
  }
}

.main_layout_wrapper {
  position: relative;
  height: 100%;
  background: radial-gradient(
    75.45% 101.63% at 82.88% 0%,
    rgba(var(--generalBrandRGB), 0.1) 0%,
    rgba(211, 114, 83, 0) 88.24%
  );
  background-color: #f5f5f5;
}

.challenge_layout {
  height: 100%;
  position: relative;
  top: 0px;
  display: flex;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0);
}

.middle {
  height: 100%;
  max-height: calc(100% - 98px);
  display: flex;
  position: relative;
}
</style>