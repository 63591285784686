import axios from "axios";
import router from "./router";
import i18n from "@/i18n";
import {notify} from "@/general-scripts/toast-notification";


function getCookie(name) {
    const match = document.cookie.match(
        new RegExp("(^| )" + name + "=([^;]+)")
    );
    if (match) return match[2];
}

export function setup() {
    axios.interceptors.request.use(
        function (config) {
            if (config.method !== "get") {
                config.headers["X-CSRFToken"] = getCookie("csrftoken");
            }

            if (document.referrer || document.URL) {
                config.headers["url"] = document.URL;
            }

            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );
    axios.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            const referer = error?.config?.headers?.url;
            const arr = referer?.split('/')
            const isReferralChallenge = arr?.includes('challenge') && arr[arr.length-1].includes('?id=');
            const responseURL = error?.request?.responseURL;
            const url = error.config.url;

            if (responseURL && !responseURL.endsWith("/user/change-organization/verify/") && !isReferralChallenge && !responseURL.includes('challenge-contenders/')) {
                const currentRoute = router.currentRoute.value;
                if ((error?.response?.status == 403 || error?.response?.status == 401)
                    && (currentRoute.name != "PublicMarket" && currentRoute.name != "Market" && currentRoute.name != "GlobalAdmin")) {
                    router.push({
                        path: "/login",
                        query: currentRoute.query
                    });
                }


                if (error?.response?.status === 404 && url.includes('/api/api/challenges/')&& !url.endsWith("/invites/")) {
                    notify('Челлендж не найден')
                }
            }
            const pattern = /^\/api\/api\/challenges\/\d+$/;

            if(pattern.test(url))  {
                router.push({
                    path: "/challenges",
                    query: {my: true}
                });
            }

            return Promise.reject(error);
        }
    )
}

