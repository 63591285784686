<template>
    <div class="auth_layout">
        <router-view></router-view>
    </div>
</template>

<script>

</script>

<style scoped>
.auth_layout {
    height: 100%;
}
</style>